import { YN } from '@constant';
import { createTimeoutPromise, dateFormat } from "@utils/utils";

export const getFormConfig = (vm) => {
  return {
    filterFormConfig: [
      {
        key: "name",
        label: "模板名称",
        placeholder: "请输入模板名称"
      },
      {
        key: "yn",
        label: "是/否",
        type: "select",
        options: YN,
        placeholder: "请选择要素类型"
      },
      {
        key: "effectMake",
        label: "有效标记",
        type: "select",
        options: async () => {
            return await createTimeoutPromise([
                { key: "a", label: "有效标记A" },
                { key: "b", label: "有效标记B" },
                { key: "c", label: "有效标记C" }
            ]);
        },
        placeholder: "请选择有效标记"
      },
      {
        key: "displayName",
        label: "要素展示名称",
        type: "textarea",
        placeholder: "请输入要素展示名称"
      },
      {
        key: "startTime",
        label: "开始时间",
        type: "date",
        col: 1,
        props: {
            "value-format": "yyyy-MM-dd"
        },
        placeholder: "请选择时间"
      },
      {
        key: "endDate",
        label: "结束时间",
        type: "date",
        col: 1,
        props: {
            "value-format": "yyyy-MM-dd"
        },
        placeholder: "请选择时间"
      },
      {
        key: "datera",
        label: "播放日期",
        placeholder: "请选择时间",
        type: "daterange",
        default: [
            dateFormat("yyyy-MM-dd", new Date()),
            dateFormat("yyyy-MM-dd", new Date()),
        ],
        props: {
            "value-format": "yyyy-MM-dd",
        },
        required: true,
        col: 2,
      },
      {
        key: "endTime",
        label: "结束时间",
        type: "time",
        col: 1,
        placeholder: "请选择时间",
        props: {
          "value-format": "HH:mm:ss"
        }
      },
    ]
  }
}