import { getFormConfig } from "./configs/formConfig";

export default {
  data: function () {
    const formConfig = getFormConfig(this)

    return {
      formData: {}, //筛选条件
      ...formConfig
    }
  },
  methods: {
    async submit() {
      let error = await new Promise(resolve => {
          this.$refs.filterForm.validate((success, error) => {
              console.log("success, error", success, error);

              if (success) {
                  resolve();
              } else {
                  resolve(error);
              }
          });
      });

      if (error) {
          return;
      }
      this.$message.success(JSON.stringify(this.$refs.filterForm.formData))
    },
  }
}